<template>
  <div class="space-detail">
    <div class="row">
      <span class="title">云空间</span>
      <a-popover>
        <template #content>
          <p>
            1、云空间是用于您在人脉宝盒平台创建记录所上传的文件内容所需云存储空间；
          </p>
          <p>
            2、每个用户默认有1G的免费云存储空间，当上传的文件超过1G时；需通过金币兑换云空间进行扩容；
          </p>
          <p>
            3、扩容兑换相应容量后有一个月有效期；当到期后云空间将会被回收；需再次进行兑换。
          </p>
        </template>
        <QuestionCircleOutlined style="color: #ff7b00" />
      </a-popover>
      <div class="add-btn" @click="toCharge">
        <span>扩容></span>
      </div>
    </div>
    <div class="row message-row">
      <span
        >使用量：{{ remainingSpace ? remainingSpace : 0 }}MB/{{
          allSpace ? allSpace : 0
        }}G</span
      >
      <span>{{ endTime }}</span>
    </div>
    <div class="row">
      <div class="bar">
        <div
          class="bar-used"
          :class="{ fulled: spacePercentNumber >= 100 }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue';
import { Popover as APopover } from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import timelineApi from '@/api/timeline';
import personalApi from '@/api/personal';

const emits = defineEmits(['openCharge']);

const remainingSpace = ref('');
const allSpace = ref('');
const spacePercent = ref('');
const spacePercentNumber = ref(0);
const endTime = ref('');

const toCharge = () => {
  emits('openCharge', 'space');
};

onMounted(() => {
  timelineApi.getUserFreeSpace('', {}).then(res => {
    remainingSpace.value = (res.remainingSpace / 1024 / 1024).toFixed(2);
    allSpace.value = (res.allSpace / 1024 / 1024 / 1024).toFixed(0);
    spacePercentNumber.value =
      (res.remainingSpace / res.allSpace).toFixed(2) * 100;
    spacePercent.value = spacePercentNumber.value + '%';
  });
  personalApi.getUserSpaceDetail('', {}).then(res => {
    if (res.endTime) {
      let time = new Date(res.endTime);
      console.log(time);
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      endTime.value = `${year}年${month}月${day}日`;
    }
  });
});
</script>

<style lang="less" scoped>
.space-detail {
  width: 100%;
  // max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.message-row {
      justify-content: space-between;

      span {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &:first-child {
      margin-top: auto;
    }

    .title {
      margin-right: 8px;
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
    }

    .add-btn {
      margin-left: auto;
      color: #ff7b00;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .bar {
      width: 100%;
      height: 8px;
      background: #eeeeee;
      border-radius: 4px;
      overflow: hidden;
      position: relative;

      .bar-used {
        width: v-bind(spacePercent);
        height: 100%;
        border-radius: 4px;
        background: #ff7b00;
        position: absolute;
        top: 0;
        left: 0;

        &.fulled {
          background: #fa5151;
        }
      }
    }
  }
}
</style>
