<template>
  <div class="iss-main contactFrequency">
    <div class="message">
      <div>
        <a-avatar :src="setAvatar(userData.avatar)" :size="144" />
        <!--         <a-avatar :src="setAvatar(value)" :size="128" />-->
      </div>
      <div>
        <div class="left">
          <div class="name">{{ userData.userName }}</div>
          <div class="activeColor sex">
            <ManOutlined v-if="userData.sex === '1'" />
            <!--           女   -->
            <WomanOutlined v-else-if="userData.sex === '0'" />
            <span v-else></span>
          </div>
        </div>
        <div class="zhongjian">
          <!--          userData.expirationTime   -->
          <div class="riqi">
            您当前套餐于{{ handleData(userData.expirationTime) }}到期
          </div>
        </div>

        <div class="you">
          <div class="chongzhi cursor-p" @click="gotozhongzhi">
            <img src="@/assets/images/play/cz.png" class="qrBiankuang" />
            <!--              订阅与充值-->
          </div>

          <div class="haoyou cursor-p" @click="gotoyaoqing">
            <img src="@/assets/images/play/hy.png" class="qrBiankuang" />
            <!--              邀请好友-->
          </div>
        </div>
      </div>
      <spaceDetail @openCharge="gotozhongzhi"></spaceDetail>
      <FormOutlined @click="goToPersoal" class="editClass activeColor" />
    </div>
    <div class="borClass"></div>
    <!--    金币商城  -->

    <div class="gold">
      <div class="goldTitle">
        <div class="goldTitleLeft">金币商城</div>

        <div class="goldTitleRight">
          <div class="goldNum">
            <div class="goldIcon">
              <img src="@/assets/images/play/jinbi.png" class="jinbiPng" />
            </div>
            <div class="goldJinbi">
              {{ userData.totalGold }}<span class="ml-4">金币</span>
            </div>
          </div>

          <div class="goldjilu cursor-p" @click="handleShouzhi">
            收支记录
            <RightOutlined class="goldjiluIcon" />
          </div>
        </div>
      </div>

      <div class="listClass">
        <div v-for="item in list" :key="item.id" class="itemValue">
          <div class="shixiao">
            <div class="shichang">{{ item.packageName }}</div>
            <div class="tian">{{ item.packageDuration }}天</div>
          </div>

          <div class="jbsl">
            <div class="jb">
              <!--           图标   -->
              <img src="@/assets/images/play/jinbi.png" class="jinbiPng mr-8" />
              {{ item.goldNumber }}
            </div>
            <div class="duihuan cursor-p" @click="handleDuihuan(item)">
              兑换
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <paly-page
    v-model:visible="palyPage"
    :activeItem="activeItem"
    @fnOk="handlePalyPage"
  />
  <recharge-page
    ref="rechargeRef"
    v-model:visible="rechargePage"
    :activeItem="rechargeItem"
    @fnOk="handleRechargePage"
  />
</template>

<script>
import {
  createVNode,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  ref,
  provide,
} from 'vue';
import { setAvatar } from '@/utils';
import playApi from '@/api/play';
import { local } from '@/utils/storage';

// Avatar, Form Tabs,
import { Col, Row, Avatar, Modal, message } from 'ant-design-vue';
import {
  FormOutlined,
  ManOutlined,
  WomanOutlined,
  RightOutlined,
} from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';
import palyPage from '@/views/personal/palyPage';
import rechargePage from '@/views/personal/rechargePage';
import spaceDetail from './components/spaceDetail.vue';

export default {
  components: {
    FormOutlined,
    AAvatar: Avatar,
    WomanOutlined,
    ManOutlined,
    RightOutlined,
    palyPage,
    rechargePage,
    spaceDetail,
  },
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const user = store.state.account.user;
    // console.log('user', user);
    const router = useRouter();
    const state = reactive({
      palyPage: false,
      rechargePage: false,
      list: [],
      userData: {},
    });

    const rechargeRef = ref();

    const form = reactive({
      avatar: '',
    });
    const rechargeItem = ref(1);

    // 从充值列表打开充值弹窗
    proxy.$bus.on('openPage', value => {
      state.rechargePage = value;
    });

    // 会员个人中心信息
    const getUserGoldNameDate = () => {
      playApi.userGoldNameDate('', {}).then(res => {
        if (res) {
          // console.log('res会员过期总人数', res)
          state.userData = res;
          local.set('userData', res);
          rechargeRef.value.getUserGoldNameDate();
        }
      });
    };

    provide('getUserGoldNameDate', getUserGoldNameDate);

    const getData = () => {
      playApi.getPackageInfoList('', { type: 2 }).then(res => {
        if (res) {
          state.list = res;
        }
      });
    };

    onMounted(() => {
      getData();
      getUserGoldNameDate();
    });

    return {
      ...toRefs(state),
      form,
      user,
      setAvatar,
      rechargeItem,
      rechargeRef,
      goToPersoal() {
        // todo 如何增加菜单栏子级
        router.push(`/personal/memberPage`);
      },
      handleShouzhi() {
        state.palyPage = true;
      },
      gotozhongzhi(e) {
        if (e == 'space') {
          rechargeItem.value = '3';
        } else {
          rechargeItem.value = '1';
        }
        state.rechargePage = true;
      },
      handlePalyPage() {
        state.palyPage = false;
      },

      gotoyaoqing() {
        router.push({ name: 'invite' });
      },

      handleDuihuan(item) {
        Modal.confirm({
          title: `您确认使用${item.goldNumber}金币兑换${item.packageDuration}天使用时长吗？`,
          icon: createVNode(icons['ExclamationCircleOutlined']),
          okText: '确认',
          onOk() {
            // todo调取兑换接口，成功并刷新列表
            playApi.exchangeDuration('', { packageId: item.id }).then(res => {
              if (res) {
                getUserGoldNameDate();
                message.success('兑换成功');
              }
            });
          },
        });
      },

      handleData(data) {
        let dateObj = new Date(data);
        if (!isNaN(dateObj.getTime())) {
          let d =
            dateObj.getFullYear() +
            '年' +
            (dateObj.getMonth() + 1) +
            '月' +
            dateObj.getDate() +
            '日';
          return d;
        } else {
          return '';
        }
      },
      handleRechargePage(value) {
        getUserGoldNameDate();
        state.rechargePage = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  padding-right: 63px;
  padding-left: 37px;
}
.message {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 37px 0px;
  gap: 40px;
  position: relative;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .name {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
      margin-right: 20px;
    }
    .sex {
      color: #ff7b00;
    }
  }

  .zhongjian {
    margin-top: 6px;
    .riqi {
      font-size: 14px;
      color: #717171;
    }
  }
  .you {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
    .chongzhi {
      //background: #fffffe;
      //border-radius: 8px;
      //border: 1px solid;
      //border-image: linear-gradient(270deg, #ff7b00 0%, #fdbb25 100%) 1;
      //box-shadow: 0px 2px 8px 0px rgba(255, 123, 0, 0.16);
      //padding: 20px 30px;
      margin-right: 24px;
    }

    // .haoyou {
    //   border: 1px solid;
    //   border-image: linear-gradient(270deg, #ff7b00 0%, #fdbb25 100%) 1;
    //   box-shadow: 0px 2px 8px 0px rgba(255, 123, 0, 0.16);
    //   padding: 20px 30px;
    // }
  }

  .editClass {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
  }
}
.borClass {
  border: 1px solid #eeeeee;
}
.gold {
  margin-top: 40px;

  .goldTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .goldTitleLeft {
      width: 80%;
      font-size: 20px;
      font-weight: 500;
    }
    .goldTitleRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20%;
      .goldNum {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 55%;
        .goldIcon {
          margin-right: 5px;
        }
        // .goldJinbi {
        // }
      }

      .goldjilu {
        .goldjiluIcon {
          margin-left: 3px;
        }
      }
    }
  }
}

.listClass {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .itemValue {
    //position: relative;
    width: 350px;
    border-radius: 16px;
    background-image: linear-gradient(
      180deg,
      #fe9404 -248%,
      rgba(255, 234, 144, 0) 94%
    );
    box-shadow: 0px 2px 8px -4px #ff7b00;
    padding: 28px;
    margin-right: 32px;
    margin-top: 28px;

    .shixiao {
      .shichang {
        font-size: 20px;
        color: #bc711b;
      }
      .tian {
        color: #bc711b;
        font-size: 28px;
      }
    }

    .jbsl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //width: 350px;
      margin-top: 32px;
      .jb {
        width: 78%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .duihuan {
        width: 26%;
        border-radius: 100px;
        background: linear-gradient(
          270deg,
          #fdbc25 0%,
          #ff8e31 37%,
          #ff7b00 86%
        );
        //filter: blur(20px);
        box-shadow: 0px 4px 8px 0px rgba(145, 70, 8, 0.2392);
        color: #ffffff;
        text-align: center;
        //padding: 4px 0px;
        padding: 1px 0px;
        font-size: 18px;
        //font-weight: 550;
        font-weight: bold;
      }
    }
  }
}

.cursor-p {
  width: 45%;
  max-width: 200px;
}

.qrBiankuang {
  width: 100%;
}
</style>
