<template>
  <a-modal
    :visible="visible"
    title="收支记录"
    :mask-closable="false"
    :width="550"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
    :footer="null"
    class="issModal"
    :bodyStyle="{ padding: '0px 14px 14px 14px' }"
  >
    <div class="tabsRight">
      <a-tabs v-model:activeKey="activeKeyRight" class="left">
        <a-tab-pane key="1" tab="全部">
          <div class="content">
            <div v-if="allList.length === 0" class="noneList">暂无记录</div>
            <div v-else>
              <div v-for="item in allList" :key="item.id" class="listClass">
                <div class="itemOne">
                  <div class="name">{{ item.packageName }}</div>
                  <div class="jinbi">{{ item.createTime }}</div>
                </div>
                <div class="itemTwo">
                  <span class="ml-4" v-if="item.tradeType === 'add'">+</span>
                  <span class="ml-4" v-if="item.tradeType === 'decrease'"
                    >-</span
                  >
                  {{ item.goldNum }}
                  <img src="@/assets/images/play/jinbi.png" class="jinbiPng" />
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="收入" force-render>
          <div class="content">
            <div v-if="addList.length === 0" class="noneList">暂无记录</div>
            <div v-else>
              <div v-for="item in addList" :key="item.id" class="listClass">
                <div class="itemOne">
                  <div class="name">{{ item.packageName }}</div>
                  <div class="jinbi">{{ item.createTime }}</div>
                </div>
                <div class="itemTwo">
                  <span class="ml-4" v-if="item.tradeType === 'add'">+</span>
                  <span class="ml-4" v-if="item.tradeType === 'decrease'"
                    >-</span
                  >
                  {{ item.goldNum }}
                  <img src="@/assets/images/play/jinbi.png" class="jinbiPng" />
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="支出" force-render>
          <div class="content">
            <div v-if="decreaseList.length === 0" class="noneList">
              暂无记录
            </div>

            <div v-else>
              <div
                v-for="item in decreaseList"
                :key="item.id"
                class="listClass"
              >
                <div class="itemOne">
                  <div class="name">{{ item.packageName }}</div>
                  <div class="jinbi">{{ item.createTime }}</div>
                </div>
                <div class="itemTwo">
                  <span class="ml-4" v-if="item.tradeType === 'add'">+</span>
                  <span class="ml-4" v-if="item.tradeType === 'decrease'"
                    >-</span
                  >
                  {{ item.goldNum }}
                  <img src="@/assets/images/play/jinbi.png" class="jinbiPng" />
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <div class="butS cursor-p" @click="gotoCZ">充值</div>
    </div>

    <!--    <template v-slot:footer>-->
    <!--      <a-button @click="handleCancel">取消</a-button>-->
    <!--      <a-button type="primary" @click="handleOk" :loading="loading"-->
    <!--        >确认</a-button-->
    <!--      >-->
    <!--    </template>-->
  </a-modal>

  <!--  </div>-->
</template>
<script>
// watch  Select, mobileRegExp  onMounted,
import { getCurrentInstance, reactive, toRefs, watch, onMounted } from 'vue';
import { Tabs } from 'ant-design-vue';
import { useStore } from 'vuex';
import playApi from '@/api/play';
// import {useRouter} from "vue-router";

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const router = useRouter();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const userId = store.state.account.user.id;
    const form = reactive({});

    const state = reactive({
      activeKeyRight: '1',
      groupsList: [],
      loading: false,
      allList: [],
      addList: [],
      decreaseList: [],
      list: [],
    });

    // watch(
    //     () => props.init,
    //     init => Object.assign(form, init)
    // );

    const getGoldDetailList = type => {
      playApi
        .goldDetailList('', {
          userId: userId,
          tradeType: type ? type : '',
        })
        .then(res => {
          if (res) {
            if (type === '') {
              console.log('收支记录1', res, type);
              state.allList = res;
            } else if (type === 'add') {
              console.log('收支记录2', res, type);
              state.addList = res;
            } else if (type === 'decrease') {
              console.log('收支记录3', res, type);
              state.decreaseList = res;
            } else {
              console.log('收支记录 null', res, type);
              state.allList = res;
            }
          }
        });
    };

    onMounted(() => {
      getGoldDetailList();
    });

    watch(
      () => state.activeKeyRight,
      value => {
        console.log('value', value);
        if (value === '1') {
          getGoldDetailList('');
        } else if (value === '2') {
          getGoldDetailList('add');
        } else if (value === '3') {
          getGoldDetailList('decrease');
        }
      }
    );

    const handleCancel = () => {
      context.emit('update:visible', false);
    };

    return {
      ...toRefs(state),
      form,
      handleCancel,
      getGoldDetailList,
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        state.loading = true;
      },
      gotoCZ() {
        context.emit('fnOk', false);
        proxy.$bus.emit('openPage', true);
      },
    };
  },
};
</script>
<style lang="less" scoped>
.tabsRight {
  height: 550px;
}
.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}
.butS {
  position: absolute;
  top: 66px;
  right: 30px;
  padding: 4px 16px;
}
.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}
</style>
